var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{directives:[{name:"show",rawName:"v-show",value:(_vm.shouldDisplay),expression:"shouldDisplay"}],staticClass:"component-header",style:(Object.assign({}, _vm.backgroundStyle, _vm.shadowStyle)),attrs:{"extended":_vm.extended,"dark":!!_vm.itemDefinition.titleBackground,"flat":"","color":_vm.color,"dense":""},scopedSlots:_vm._u([(_vm.extended)?{key:"extension",fn:function(){
var _obj;
return [_c('v-toolbar-title',[(_vm.editing)?_c('span',{staticClass:"component-drag-handle"},[_c('v-icon',{class:( _obj = {}, _obj[((_vm.itemDefinition.color) + "--text")] = !_vm.itemDefinition.titleBackground, _obj ),attrs:{"left":""}},[_vm._v("drag_handle")])],1):_vm._e(),_vm._v(" "+_vm._s(_vm.title)+" ")])]},proxy:true}:null],null,true)},[(!_vm.extended)?_c('v-toolbar-title',[(_vm.editing)?_c('span',{staticClass:"component-drag-handle"},[_c('v-icon',{class:( _obj = {}, _obj[((_vm.itemDefinition.color) + "--text")] = !_vm.itemDefinition.titleBackground, _obj ),attrs:{"left":""}},[_vm._v("drag_handle")])],1):_vm._e(),_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),_c('v-spacer'),(_vm.itemDefinition.helpBlockId)?_c('v-dialog',{attrs:{"width":"1024"},model:{value:(_vm.helpOpen),callback:function ($$v) {_vm.helpOpen=$$v},expression:"helpOpen"}},[_c('v-card',[(_vm.helpOpen)?_c('Block',{attrs:{"environmentId":_vm.environmentId,"componentId":_vm.itemDefinition.helpBlockId,"itemDefinition":{
            id: _vm.itemDefinition.helpBlockId,
            type: 'block',
            sizeSmall: '12',
            sizeLarge: '12',
            blockId: _vm.itemDefinition.helpBlockId,
            namespace: 'blocks'
          },"preview":_vm.editing,"viewParams":_vm.$parent.viewParams,"showCloseButton":""},on:{"close":function($event){_vm.helpOpen = false}}}):_vm._e()],1)],1):_vm._e(),(_vm.itemDefinition.fullSize && !_vm.$parent.preview)?_c('v-dialog',{attrs:{"fullscreen":""},model:{value:(_vm.fullSize),callback:function ($$v) {_vm.fullSize=$$v},expression:"fullSize"}},[(_vm.fullSize)?_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","title":"Reducir (ESC)"},on:{"click":function($event){_vm.fullSize = false}}},[_c('v-icon',[_vm._v("fullscreen_exit")])],1)],1),_c(_vm.componentType.view,_vm._b({tag:"component",attrs:{"environmentId":_vm.environmentId,"componentId":_vm.componentId,"itemDefinition":Object.assign({}, _vm.itemDefinition,
              {fullSize: false}),"viewParams":_vm.$parent.viewParams,"preview":_vm.$parent.preview,"editing":_vm.editing},on:{"setParams":function($event){return _vm.$parent.$emit('setParams', $event)}}},'component',_vm.itemDefinition.bind,false))],1):_vm._e()],1):_vm._e(),_c('span',{staticClass:"d-print-none"},[_vm._t("default")],2),(_vm.editLinks && !_vm.editing)?_c('v-btn',{staticClass:"d-print-none",attrs:{"icon":"","title":"Editar componente","href":(_vm.adminLink + "/" + (_vm.itemDefinition.namespace) + "/" + (_vm.itemDefinition.id)),"target":"_blank"}},[_c('v-icon',[_vm._v("edit")])],1):_vm._e(),(_vm.isAdmin && _vm.editing)?_c('v-btn',{staticClass:"d-print-none",attrs:{"icon":"","title":"Editar componente"},on:{"click":function($event){return _vm.$parent.$emit('edit')}}},[_c('v-icon',[_vm._v("edit")])],1):_vm._e(),(_vm.itemDefinition.helpBlockId)?_c('v-btn',{staticClass:"d-print-none",attrs:{"icon":"","text":"","color":_vm.helpButtonColor},on:{"click":_vm.openHelp}},[_c('v-icon',[_vm._v("info")])],1):_vm._e(),(_vm.itemDefinition.fullSize)?_c('v-btn',{staticClass:"d-print-none",attrs:{"disabled":_vm.$parent.preview,"icon":"","title":"Expandir"},on:{"click":function($event){_vm.fullSize = true}}},[_c('v-icon',[_vm._v("fullscreen")])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }